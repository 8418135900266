<template>
        <v-dialog persistent v-model="dialog" max-width="640">
    <div>
        <v-card>
            <v-card-title>健康顾问</v-card-title>
            <v-card-text>
                <v-form ref="formCsl">
                    <v-container>
                        <v-row>
                            <v-col sm="6" md="4"><v-text-field label="用户名" v-model="csl.username" :readonly="mode1==='edit'"
								:rules="textRules.concat(usernameRules)" :error-messages="usernameerr" required @input="usernameerr=''"/></v-col>
                            <v-col sm="6" md="4"><v-text-field label="姓名" v-model="csl.name" :rules="textRules" required/></v-col>
                            <v-col sm="6" md="4"><v-text-field label="电话" v-model="csl.phone" :rules="textRules.concat(phoneRules)" :counter="11" required/></v-col>
                            <v-col sm="6" md="4"><v-select label="诊所" :items="regions" multiple v-model="csl.region" :rules="regionRules" required/></v-col>
                            <v-col sm="6" md="4"><v-text-field label="职位" v-model="csl.title" :rules="textRules" required/></v-col>
                            <v-col sm="6" md="4"><v-text-field label="级别" v-model="csl.rank" :rules="rankRules" required/></v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="12" md="8" lg="8"><v-textarea label="简介" outlined v-model="csl.introduction" :rules="textRules" required/></v-col>
                            <v-col sm="4" md="4" lg="4" style="text-align:center">
								<v-avatar center size="100" >
									<v-img v-if="avatar" :src="avatar"/>
									<div v-else>
										<v-icon>mdi-account-circle</v-icon>
									</div>
								</v-avatar>
								<v-file-input placeholder="选取一张图片" label="头像" accept="image/jpeg"
									:rules="avatarRules" style="width:200px" v-model="avatarFile" @change="onFileChange"/>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click.stop="save" :loading="loading" :disabled="loading">保存</v-btn>
                <v-btn @click.stop="hide">取消</v-btn>
            </v-card-actions>
        </v-card>
    </div>
        </v-dialog>
</template>

<script>
import BMF from 'browser-md5-file';
import {promisify} from 'es6-promisify';
const bmf = new BMF;
const md5p = promisify(bmf.md5.bind(bmf));

export default {
	data() {
		return {
			dialog: false,
			csl: {},
			avatar: null,
			avatarFile: null,
			mode1: '',
			regions: [],
			usernameRules: [v => (/^[0-9a-zA-Z]*$/g).test(v) || '只允许字母和数字'],
			phoneRules: [v => (/^[1][3,4,5,6,7,8,9][0-9]{9}$/).test(v) || '手机号格式不正确'],
			textRules: [v => !!v || '此项为必填'],
			regionRules: [v => !!v.length || '此项为必填'],
			avatarRules: [],
			rankRules: [v => (/^[0-9]+$/g).test(v) || '只允许数字'],
			usernameerr: '',
			loading: false
		}
	},
	mounted() {
		const db = this.$tcbapp.database();
		const regionsdb = db.collection('region');
		regionsdb.where({show:true}).orderBy('rank','asc').field({name:true}).get().then(res => {
			this.regions = res.data.map(v=>v.name);
		});
	},
	methods: {
		show(csl) {
			if (csl) {
				this.csl = Object.assign(this.csl, csl);
				delete this.csl.avatarURL;
				this.avatar = csl.avatarURL;
				this.avatarFile = null;
				this.mode1 = 'edit';
				this.avatarRules = [];
			} else {
				this.csl = {
					username: '',
					name: '',
					phone: '',
					region: [],
					introduction: '',
					avatar: '',
					title: '健康顾问',
					rank: 500,
					disabled: false
				};
				this.avatar = null;
				this.avatarFile = null;
				this.mode1 = 'add';
				this.avatarRules = this.textRules;
			}
			this.dialog = true;
			this.$nextTick(() => {
				this.$refs.formCsl.resetValidation();
			});
		},
		hide() {
			this.dialog = false
		},
		onFileChange(e) {
			if (e) {
				this.avatarFile = e;
				this.avatar = URL.createObjectURL(e);
			} else {
				this.avatarFile = null;
				this.avatar = null;
			}
		},
		async saveAvatar(file, username) {
			const md5 = await md5p(file);
			const filename = 'consultants/avatar/' + username + '_' + md5 + '.jpg';
			const rf = await this.$tcbapp.uploadFile({
				cloudPath: filename,
				filePath: file
			});
			await this.$tcbapp.callFunction({name:"writeLog", data:{title:'修改头像'}});
			return rf.fileID;
		},
		save() {
			this.$refs.formCsl.validate();
			if (!this.$refs.formCsl.validate()) return;
			this.csl.rank = parseInt(this.csl.rank);
			let f = async() => {
				this.loading = true;
				const db = this.$tcbapp.database();
				const consultantdb = db.collection('consultants');
				if (this.mode1 === 'add') {
					const userdb = db.collection('user');
					try {
						const r = await userdb.where({username:this.csl.username}).get();
						if (r.data.length === 0) {
							this.usernameerr = '没有此用户';
						} else {
							this.csl.avatar = await this.saveAvatar(this.avatarFile, this.csl.username);
							await consultantdb.add({...this.csl});
							this.$root.$emit('ConsultantUpdated');
							this.dialog = false;
						}
					} catch(err) {
						if (err.message.includes('duplicate')) {
							this.usernameerr = '此用户名对应的健康顾问已存在';
						} else {
							console.error(err);
						}
					}
				} else {
					if (this.avatarFile) {
						this.csl.avatar = await this.saveAvatar(this.avatarFile, this.csl.username);
					} else {
						delete this.csl.avatar;
					}
					const _id = this.csl._id;
					delete this.csl._id;
					delete this.csl._openid;
					await consultantdb.doc(_id).update({...this.csl});
					await this.$tcbapp.callFunction({name:"writeLog", data:{title:'修改健康顾问信息',content:this.csl}});
					this.$root.$emit('ConsultantUpdated');
					this.dialog = false;
				}
				this.loading = false;
			};
			f();
		}
	}
}
</script>