var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.authed)?_c('div',[_c('splitpanes',{staticClass:"default-theme",staticStyle:{"height":"90vh"},attrs:{"horizontal":""}},[_c('pane',{attrs:{"min-size":"10","size":"35"}},[_c('div',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"100%"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"_id","options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"loading-text":"加载中...","show-select":"","single-select":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"pt-3",staticStyle:{"float":"left"}},[_c('v-icon',{staticClass:"ml-4",attrs:{"large":""},on:{"click":function($event){$event.stopPropagation();return _vm.addItem($event)}}},[_vm._v("mdi-account-plus")])],1)]},proxy:true},{key:"item.avatarURL",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"40"}},[_c('v-img',{attrs:{"src":item.avatarURL}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")])]}}],null,false,3200058053),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)]),((_vm.selected.length > 0))?_c('pane',{attrs:{"min-size":"20","size":"65"}},[_c('hr'),_c('div',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"100%"}},[_c('v-data-table',{attrs:{"headers":_vm.headers1,"items":_vm.items1,"item-key":"_id","dense":"","options":_vm.options1,"server-items-length":_vm.totalItems1,"loading":_vm.loading1,"loading-text":"加载中...","show-select":""},on:{"update:options":function($event){_vm.options1=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"pt-3",staticStyle:{"float":"left"}},[_c('v-icon',{staticClass:"ml-4",attrs:{"large":""},on:{"click":function($event){$event.stopPropagation();return _vm.switchItem($event)}}},[_vm._v("mdi-account-switch-outline")])],1)]},proxy:true},{key:"item.opts",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getOptsStr(item))+" ")]}},{key:"item.progress.payment",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.progress.payment))+" ")]}},{key:"item.progress.operative",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.progress.operative))+" ")]}}],null,false,2152007759),model:{value:(_vm.selected1),callback:function ($$v) {_vm.selected1=$$v},expression:"selected1"}})],1)]):_vm._e()],1),_c('consultant',{ref:"dialog"}),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.switchDlg),callback:function ($$v) {_vm.switchDlg=$$v},expression:"switchDlg"}},[_c('v-card',[_c('v-card-title',[_vm._v("变更健康顾问")]),_c('v-card-text',[_c('v-form',{ref:"switchForm"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"readonly":"","label":"当前健康顾问","value":_vm.selected.length > 0 ? _vm.selected[0].name : ''}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"变更为","items":_vm.cnslts,"item-text":"name","item-value":"_id","rules":_vm.cnltRules},model:{value:(_vm.newConsultant),callback:function ($$v) {_vm.newConsultant=$$v},expression:"newConsultant"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-radio-group',{staticClass:"mt-0",attrs:{"mandatory":"","dense":"","hide-details":""},model:{value:(_vm.swmode),callback:function ($$v) {_vm.swmode=$$v},expression:"swmode"}},[_c('v-radio',{attrs:{"label":("变更选中的" + (_vm.selected1.length) + "位客户"),"value":"0"}}),_c('v-radio',{attrs:{"label":("变更" + (_vm.selected.length > 0 ? _vm.selected[0].name : '') + "服务的全部客户"),"value":"1"}})],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.change($event)}}},[_vm._v("确定")]),_c('v-btn',{on:{"click":function($event){$event.stopPropagation();_vm.switchDlg=false}}},[_vm._v("取消")])],1)],1)],1)],1):_c('div',[_vm._v(" 无此权限 ")])}
var staticRenderFns = []

export { render, staticRenderFns }