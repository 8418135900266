<template>
	<div v-if="authed">
		<splitpanes horizontal class="default-theme" style="height:90vh">
			<pane min-size="10" size="35">
				<div style="max-height: 100%" class="overflow-y-auto">
					<v-data-table :headers="headers" :items="items" item-key="_id" v-model="selected"
						:options.sync="options" :server-items-length="totalItems"
						:loading="loading" loading-text="加载中..." show-select single-select>
						<template v-slot:footer>
							<div class="pt-3" style="float:left">
				                <v-icon large class="ml-4" @click.stop="addItem">mdi-account-plus</v-icon>
							</div>
						</template>
						<template v-slot:item.avatarURL="{ item }">
							<v-avatar size="40">
								<v-img :src="item.avatarURL"/>
							</v-avatar>
						</template>
						<template v-slot:item.actions="{ item }">
							<v-icon class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
						</template>
					</v-data-table>
				</div>
			</pane>
			<pane min-size="20" size="65" v-if="(selected.length > 0)">
				<hr/>
				<div style="max-height: 100%" class="overflow-y-auto">
					<v-data-table :headers="headers1" :items="items1" item-key="_id" v-model="selected1" dense
						:options.sync="options1" :server-items-length="totalItems1"
						:loading="loading1" loading-text="加载中..." show-select>
						<template v-slot:footer>
							<div class="pt-3" style="float:left">
				                <v-icon large class="ml-4" @click.stop="switchItem">mdi-account-switch-outline</v-icon>
							</div>
						</template>
						<template v-slot:item.opts="{ item }">
							{{getOptsStr(item)}}
						</template>
						<template v-slot:item.progress.payment="{ item }">
							{{formatTime(item.progress.payment)}}
						</template>
						<template v-slot:item.progress.operative="{ item }">
							{{formatTime(item.progress.operative)}}
						</template>
					</v-data-table>
	            </div>
			</pane>
		</splitpanes>
		<consultant ref="dialog"></consultant>
		<v-dialog v-model="switchDlg" width="400">
			<v-card>
				<v-card-title>变更健康顾问</v-card-title>
				<v-card-text>
					<v-form ref="switchForm">
						<v-row dense>
							<v-col cols="6">
								<v-text-field readonly label="当前健康顾问" :value="selected.length > 0 ? selected[0].name : ''"/>
							</v-col>
							<v-col cols="6">
								<v-select label="变更为" :items="cnslts" item-text="name" item-value="_id" v-model="newConsultant" :rules="cnltRules"/>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="12">
								<v-radio-group mandatory dense hide-details v-model="swmode" class="mt-0">
									<v-radio :label="`变更选中的${selected1.length}位客户`" value="0"/>
									<v-radio :label="`变更${selected.length > 0 ? selected[0].name : ''}服务的全部客户`" value="1"/>
								</v-radio-group>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				<v-divider/>
				<v-card-actions>
					<v-spacer/>
					<v-btn color="primary" @click.stop="change">确定</v-btn>
					<v-btn @click.stop="switchDlg=false">取消</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
	<div v-else>
		无此权限
	</div>
</template>

<script>
    import {formatTime} from '../utils'
	import consultant from "../components/Consultant.vue"
    import { Splitpanes, Pane } from 'splitpanes'
    import 'splitpanes/dist/splitpanes.css'

	export default {
		name: 'Consultant',
		data() {
			return {
				authed: false,
				loading: false,
				headers: [
					{text:'', value:'avatarURL', width:100},
					{text:'姓名', value:'name', width:100},
					{text:'电话', value:'phone', width:150},
					{text:'诊所', value:'region', width:180},
					{text:'职位', value:'title', width:120},
					{text:'级别', value:'rank', width:80},
					{text:'简介', value:'introduction', sortable: false},
					{text:'修改', value:'actions', width:80, sortable: false}
				],
				items: [],
				selected: [],
				options: {},
				totalItems: 0,
				loading1: false,
				headers1: [
                    {text:'订单号', value:'_id', width:120},
                    {text:'姓名', value:'pii.username', width:100},
                    {text:'性别', value:'pii.gender', width:80},
                    {text:'电话', value:'pii.phone', width:100},
                    {text:'微信名', value:'userInfo.nickName', width:120},
                    {text:'诊所', value:'region', width:100},
                    {text:'套餐内容', value:'opts', width:200, sortable: false},
                    {text:'付款时间', value:'progress.payment', width:180},
                    {text:'手术时间', value:'progress.operative', width:180},
				],
				items1: [],
				selected1: [],
				options1: {},
				totalItems1: 0,
				switchDlg: false,
				newConsultant: null,
				cnslts: [],
				swmode: null,
				cnltRules: [v => !!v || '此项为必填'],
			}
		},
		mounted() {
            this.formatTime = formatTime;
			this.authed = this.$hasPrivilege('健康顾问管理');
			if (!this.authed) return;
			this.$root.$off('ConsultantUpdated');
			this.$root.$on('ConsultantUpdated', this.fetchData);
			this.fetchData();
		},
		methods: {
            getOptsStr(item) {
				return item.opts.GIE + '/' + item.opts.ANES + '/' + item.opts.PRO;
            },
			async fetchData() {
				const db = this.$tcbapp.database();
				const collection = db.collection('consultants');
				this.loading = true;
				try {
					const countRes = await collection.count();
					this.totalItems = countRes.total;
					let { sortBy, sortDesc, page, itemsPerPage } = this.options;
					if (itemsPerPage === -1) itemsPerPage = 200;
					const res = await collection.orderBy('rank','desc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					const fileList = res.data.map(x => x.avatar);
					const r1 = await this.$tcbapp.getTempFileURL({fileList});
					r1.fileList.forEach((el,index) => {
						if (el.code === 'SUCCESS') {
							res.data[index].avatarURL = el.tempFileURL;
						}
					});
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			async fetchOrder() {
				if (this.selected.length === 0) return;
				const db = this.$tcbapp.database();
				const _ = db.command;
				const f = [
					{consultantId:this.selected[0]._id},
					{'hide':_.neq(true)},
				];
				this.loading1 = true;
				try {
                    const countRes = await db.collection('wp2order').where(_.and(f)).count();
					this.totalItems1 = countRes.total;
					let { sortBy, sortDesc, page, itemsPerPage } = this.options1;
					if (itemsPerPage === -1) itemsPerPage = 200;
					const res = await db.collection('wp2order').where(_.and(f)).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items1 = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading1 = false;
			},
			editItem(item) {
				this.$refs.dialog.show(item);
			},
			addItem() {
				this.$refs.dialog.show();
			},
			async switchItem() {
				;
				const db = this.$tcbapp.database();
				const _ = db.command;
				try {
					const res = await db.collection('consultants').where({rank:_.gt(0)}).orderBy('rank', 'desc').field({name:true}).get();
					this.cnslts = res.data.filter(x => x._id !== this.selected[0]._id);
					this.switchDlg = true;
				} catch(err) {
					console.error(err);
				}
			},
			async change() {
				this.$refs.switchForm.validate();
				if (!this.$refs.switchForm.validate()) return;
				let data = {toConsultantId: this.newConsultant};
				if (this.swmode == 0) {
					if (this.selected1.length === 0) return;
					data.orders = this.selected1.map(x => x._id);
				} else {
					data.fromConsultantId = this.selected[0]._id;
				}
				try {
					await this.$tcbapp.callFunction({name:"wp2mp", data:{
						funcname:'changeConsultant',
						data
					}});
					this.selected1 = [];
					await this.fetchOrder();
					this.switchDlg = false;
				} catch(err) {
					console.error(err);
				}
			}
		},
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			options1: {
				handler () {
					this.fetchOrder()
				},
				deep: true,
			},
			selected(newitem, olditem) {
				if (newitem.length === 0) return;
				this.fetchOrder();
			},
		},
		components: {
			Splitpanes, Pane, consultant
		}
	}
</script>
